
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

/*----- MAIN FONT STARTS HERE -----*/
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,600,700,800&display=swap);
:root {
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !important;
}
html {
    font-family: sans-serif !important;
    background-color: transparent !important;
    font-size: 18px !important;
}
body {
    background-color: #FCFCFC !important;
    font-family: 'Nunito Sans', sans-serif !important;
}
/*----- MAIN FONT ENDS HERE -----*/

/*----- HELPERS STARTS HERE -----*/
.heading {
    font-size: 2rem;
    margin-bottom: 2rem;
}

.heading-green {
}
.heading-green::before {
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    left: 49px;
    width: 35px;
    height: 4px;
    background-color: #44458D;
}
.heading-green::after {
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    width: 35px;
    height: 4px;
    background-color: #448D8C;
}
.heading-purple {
}
.heading-purple::before {
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    left: 49px;
    width: 35px;
    height: 4px;
    background-color: #448D8C;
}
.heading-purple::after {
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    width: 35px;
    height: 4px;
    background-color: #44458D;
}
@media screen and (min-width: 1024px) {
    .heading {
        font-size: 2.8rem;
    }
}
.pointer {
    cursor: pointer;
}
.divider-sm {
    height: 0.5rem;
}
.divider {
    height: 1rem;
}
.divider-md {
    height: 1.5rem;
}
.divider-lg {
    height: 2rem;
}
.divider-xl {
    height: 3rem;
}
.hr-primary {
    border-top: 1px solid #263c50;
}
.img-shadow {
    box-shadow: .25rem .25rem 0 rgba(0, 0, 0, 0.2);
}
.shadow-sm {
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
}
.shadow {
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.15);
}
.shadow-lg {
    box-shadow: 0 1.25rem 3rem rgba(0, 0, 0, 0.175);
}
.shadow-none {
    box-shadow: none;
}
.icon-shadow {
    text-shadow: .25rem .25rem 0 rgba(0, 0, 0, 0.2);
}
.icon-shadow-sm {
    text-shadow: .1rem .1rem 0 rgba(0, 0, 0, 0.2);
}
.text-bb-secondary {
    border-bottom: 1px solid rgba(141, 103, 68, 0.4);
    border-left: 1px solid rgba(141, 103, 68, 0.4);
}
.text-bb-muted {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    border-left: 1px solid rgba(0, 0, 0, 0.07);
}
.text-muted-alt {
    color: #b2c5d9;
}
.text-muted-primary-alt {
    color: #284866;
}
.border {
    border: 1px solid #ECECEC !important;
}
.border-primary-alt {
    border: 1px solid #284866;
}
.border-primary-alt-lg {
    border: 6px solid #284866;
}
/*----- HELPERS ENDS HERE -----*/

/*----- SECTIONS STARTS HERE -----*/
section {
    padding-bottom: 18vh;
    padding-top: 18vh;
}
section:first-of-type {
    // padding-top: 5vh !important;
}
.border-top-detail-primary {
    border-top: 65px solid #284866;
}
.border-top-detail-black {
    border-top: 65px solid #0b1925;
}
.border-top-detail-white {
    border-top: 65px solid #d6d6d6;
}
.border-top-detail-green {
    border-top: 65px solid #448D8C;
}
.border-top-detail-purple {
    border-top: 65px solid #2e2f73;
}
/*----- SECTIONS ENDS HERE -----*/

/*----- BACKGROUNDS STARTS HERE -----*/
.bg-primary-alt {
    background-color: #284866 !important;
}
/*----- BACKGROUNDS ENDS HERE -----*/

/*----- BACKGROUND GRADIENTS STARTS HERE -----*/
.bg-primary-gradient {
    // box-shadow: 0px 0px 10px #446a8d;
    background-image: linear-gradient(#446a8d, #305374);
}
.bg-secondary-gradient {
    // box-shadow: 0px 0px 10px #8D6744;
    background-image: linear-gradient(#8D6744, #573b1f);
}
.bg-primary-analogous-gradient {
    // box-shadow: 0px 0px 10px #44458D;
    background-image: linear-gradient(#44458D, #2e2f73);
}
.bg-info-gradient {
    background-image: linear-gradient(#1DB0E2, #2f96b9);
}
/*----- BACKGROUND GRADIENTS ENDS HERE -----*/

/*----- CARD STARTS HERE -----*/
.card {
    border: none !important;
    border-radius: 3px !important;
    box-shadow: none !important;
}
.card-border-success {
    border-left: 1px solid rgba(68, 141, 140, 0.2) !important;
    border-right: 1px solid rgba(68, 141, 140, 0.2) !important;
    border-bottom: 1px solid rgba(68, 141, 140, 0.2) !important;
    border-top: 15px solid #448D8C !important;
}
.card-border-warning {
    border-left: 1px solid rgba(68, 69, 141, 0.2) !important;
    border-right: 1px solid rgba(68, 69, 141, 0.2) !important;
    border-bottom: 1px solid rgba(68, 69, 141, 0.2) !important;
    border-top: 15px solid #44458D !important;
}
.card-border-secondary {
    border-left: 1px solid rgba(141, 103, 68, 0.2) !important;
    border-right: 1px solid rgba(141, 103, 68, 0.2) !important;
    border-bottom: 1px solid rgba(141, 103, 68, 0.2) !important;
    border-top: 15px solid #8D6744 !important;
}
@media screen and (min-width: 1024px) {
    .card-border-success {
        border-top-width: 25px !important;
    }
    .card-border-warning {
        border-top-width: 25px !important;
    }
    .card-border-secondary {
        border-top-width: 25px !important;
    }
}
.card-img-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
p.goals-card-text strong:first-of-type, .text-primary-emphasis  {
    color: #446a8d !important;
    font-size: 1.5rem;
}
/*----- CARD ENDS HERE -----*/

/*----- BANNER STARTS HERE -----*/
.banner {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 1000px;
    position: relative;
}

.banner-box {
    width: 100%;
    position: absolute;
    top: 40vh;
    left: 0;
}

.banner-text {
    text-align: center;
    color: white;
    text-shadow: 0px 0px 1px black;
}

.funding-banner-text {
    text-align: left;
    color: white;
    text-shadow: 0px 0px 1px black;
}

.banner-logo {
    width: 70%;
}

@media screen and (min-width: 1024px) {
    .banner-text {
        text-align: left;
    }
    .banner-logo {
        width: 38%;
    }
}

.banner-main-text {
    font-size: 4rem;
    font-weight: 600 !important;
}

.banner-sub-text {
    font-size: 1.5rem;
}
/*----- BANNER ENDS HERE -----*/

/*----- FONT AWESOME STARTS HERE -----*/
.icon-linkedin {
    color: #0976b4;
}

.icon-twitter {
    color: #55acee;
}

.fa-stack-1x {
    color: white;
}

.fa-hover {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.fa-hover:hover {
    color: #D9B310;
}

.fa-sri {
    color: #D9B310;
}

.fa-tv {
    margin-left: 2px;
    margin-top: 1px;
}

.fa-4x-mobile {
    font-size: 4em;
}

@media screen and (max-width: 768px) {
    .fa-4x-mobile {
        font-size: 2em;
    }
}
/*----- FONT AWESOME ENDS HERE -----*/

/*----- FORMS STARTS HERE -----*/
input, textarea, button {
    border-radius: 0 !important;
    box-shadow: none !important;
}

button:disabled,
button[disabled] {
    cursor: not-allowed !important;
}
/*----- FORMS ENDS HERE -----*/
